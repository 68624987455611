<template>
    <div class="empresa">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Empresa'
    },
}
</script>

<style>

</style>